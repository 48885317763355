<template>
    <b-container>
        <b-card>
            <b-row class="border rounded p-2 justify-content-center align-items-center mx-0">
                <b-col cols="12" lg="3" md="3" sm="12">
                    <h3 class="mb-lg-0 mb-md-0">Keyword Research</h3>
                </b-col>
                <b-col cols="12" lg="7" md="7" sm="12">
                    <b-form-group class="mb-lg-0 mb-md-0">
                        <input type="text" class="form-control" placeholder="Enter The Keyword."
                            v-model="keywordName" />
                        <small class="text-danger keyword-name-error"></small>
                    </b-form-group>
                </b-col>
                <b-col cols="6" lg="1" md="1" sm="6">
                    <b-button @click="searchKeywords" variant="outline-info" class="w-100 circle" type="submit" v-b-tooltip.hover
                        title="Search">
                        <feather-icon icon="SearchIcon" size="14" />
                    </b-button>
                </b-col>
                <b-col cols="6" lg="1" md="1" sm="6">
                    <b-button :variant="`${checked ? 'primary' : 'outline-secondary'}`"
                        class="w-100 cursor-pointer select-none" v-b-tooltip.hover title="Filter"
                        @click="checked = !checked">
                        <feather-icon icon="FilterIcon" size="14" />
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
        <b-card v-if="!checked">
            <b-row class="border rounded mx-0 p-2 ">
                <b-col cols="12" lg="4" md="6" sm="12">
                    <h5 class="mb-0">Account :</h5>
                    <small class="text-muted"> Choose The Account Name. </small>
                    <v-select :clearable="false" class="mb-lg-1 mb-md-1" v-model="accountName" :options="accountNames" />
                    <small class="text-danger package-name-error"></small>
                </b-col>
                <b-col lg="4" md="6" sm="12">
                    <h5 class="mb-0">Application :</h5>
                    <small class="text-muted"> Choose The Application Name. </small>
                    <v-select :clearable="false" class="mb-lg-1 mb-md-1" v-model="packageName" :options="packageNames" />
                    <small class="text-danger package-name-error"></small>
                </b-col>
                <b-col lg="4" md="6" sm="12">
                    <h5 class="mb-0">Period :</h5>
                    <small class="text-muted"> Choose The Import Period. </small>
                    <v-select :clearable="false" class="mb-lg-1 mb-md-1" v-model="period" :options="periods" />
                    <small class="text-danger package-name-error"></small>
                </b-col>
                <b-col lg="8" md="6" sm="12">
                    <h5 class="mb-0">Exclude :</h5>
                    <small class="text-muted"> Exclude Ex: All, Test... </small>
                    <b-form-group>
                        <b-form-tags placeholder="Add Exclude Tags..." v-model="excludesTags"></b-form-tags>
                    </b-form-group>
                </b-col>
                <b-col lg="4" md="6" sm="12">
                    <h5 class="mb-2">Exclude All search terms & Other :</h5>
                    <input type="checkbox" style="width: 40px; height: 40px" v-model="exclude" />
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <div v-if="!isLoading && !noData">
                <b-table striped hover responsive :items="keywords" :fields="fields" :per-page="perPage"
                    :current-page="currentPage">
                    <template #cell(percentage)="data">
                        <p v-if="data.item.percentage">
                            {{ data.item.percentage.toFixed(2) }} %
                        </p>
                    </template>
                </b-table>
                <b-row>
                    <b-col cols="12" lg="6" md="6" sm="6">
                        <b-form-group class="mb-0">
                            <label class="d-inline-block text-sm-left mr-50">Per page</label>
                            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                            class="w-25" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="6" md="6" sm="6">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="end"
                        size="sm" class="my-0" />
                    </b-col>
                </b-row>
            </div>
            <div style="height: 300px;" v-else-if="isLoading && !noData"
                class="d-flex justify-content-center align-items-center">
                <b-spinner variant="primary" style="width: 4rem; height: 4rem;" label="Large Spinner"></b-spinner>
            </div>
            <div v-else-if="!isLoading && noData" class="text-center">
                No Data Available
            </div>
        </b-card>
    </b-container>
</template>

<script>
import { BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormSelect, BButton, BFormTags , BContainer, BPagination, VBTooltip, BFormCheckbox, BTable, BSpinner } from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";
import router from "@/router";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";

export default {
    components: { BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormSelect, BButton, BFormTags, BContainer, BPagination, BFormCheckbox, vSelect, BTable, BSpinner },
    directives: { "b-tooltip": VBTooltip },
    data() {
        return {
            checked: false,
            keywordName: "",
            accountName: "",
            accountNames: [],
            packageName: "",
            packageNames: [],
            period:  "",
            periods: [],
            exclude: false,
            excludesTags: [],
            isLoading: false,
            noData: false,
            keywords: [],
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 100],
            fields: [
                {
                    key: "app",
                    label: "Application",
                },
                {
                    key: "name",
                    label: "Keyword",
                },
                {
                    key: "listing_visitors",
                    label: "listing visitors",
                    sortable: true,
                },
                {
                    key: "listing_acquisitions",
                    label: "Acquisitions",
                    sortable: true,
                },
                {
                    key: "percentage",
                    label: "Percentage",
                    sortable: true,
                },
                {
                    key: "account_name",
                    label: "Account",
                },
            ]
        };
    },
    computed: {
        totalRows() {
            return this.keywords.length;
        }
    },
    watch: {
        accountName(){
            this.getAppsByAccount();
        }

    },
    mounted(){
        this.showAllAccounts();
        this.getPeriods();
    },
    methods: {
        async searchKeywords(){
            try{
                this.isLoading = true;
                this.noData = false;
                const userToken = JSON.parse(localStorage.getItem("accessToken"));

                const filterObject = {};

                if (this.keywordName) filterObject.keywordName = this.keywordName;
                if (this.accountName?.value) filterObject.accountId = this.accountName.value;
                if (this.packageName?.value) filterObject.appId = this.packageName.value;
                if(this.exclude) filterObject.exclude = this.exclude;
                if(this.excludesTags.length > 0) filterObject.excludesTags = this.excludesTags;

                let start_period, end_period;
                if (this.period?.value) {
                    [start_period, end_period] = this.period.value.split(" To ");
                    filterObject.start_period = start_period;
                    filterObject.end_period = end_period;
                }

                const response = await axios.post("/new-get-keywords", {
                    user_token: userToken,
                    ...filterObject
                });

                if (response?.data?.status === "Unauthorized") {
                    removeLocalStorageItems();
                    router.push({ name: "auth-login" });
                    return;
                }

                if (response?.data?.keywords) {
                    if(response?.data?.keywords.length > 0){
                        this.keywords = response.data.keywords.map(keyword => {
                            return {
                                app: keyword.app.title,
                                name: keyword.name,
                                listing_visitors: keyword.listing_visitors,
                                listing_acquisitions: keyword.listing_acquisitions,
                                percentage: (keyword.listing_visitors * 100) / keyword.listing_acquisitions,
                                account_name: keyword.app.account.account_name
                                
                            }
                        });
                        this.isLoading = false;
                    }else{
                        this.isLoading = false;
                        this.noData = true;
                    }
                    return;
                }
                
                removeLocalStorageItems();
                router.push({ name: "auth-login" });
            }catch(error){
                console.error(error);
            }
        },
        async getPeriods(){
            try{
                const userToken = JSON.parse(localStorage.getItem("accessToken"));

                const response = await axios.post("/new-get-periods", {
                    user_token: userToken,
                });

                if (response?.data?.status === "Unauthorized") {
                    removeLocalStorageItems();
                    router.push({ name: "auth-login" });
                    return;
                }

                if (response?.data?.periods) {
                    this.periods = response.data.periods.map(period => ({ 
                        label: `${period.start_period + " To " + period.end_period}`,
                        value: `${period.start_period + " To " + period.end_period}`
                    }));
                    return;
                }

                removeLocalStorageItems();
                router.push({ name: "auth-login" });
            }catch(error){
                console.error(error);
            }
        },
        async getAppsByAccount() {
            try {
                const userToken = JSON.parse(localStorage.getItem("accessToken"));
                const accountId = this.accountName.value;

                const response = await axios.post("/new-get-apps", {
                    user_token: userToken,
                    account_id: accountId
                });

                if (response?.data?.status === "Unauthorized") {
                    removeLocalStorageItems();
                    router.push({ name: "auth-login" });
                    return;
                }

                if (response?.data?.apps) {
                    this.packageNames = response.data.apps.map(app => ({ label: app.title, value: app.id }));
                    return;
                }

                removeLocalStorageItems();
                router.push({ name: "auth-login" });
            } catch (error) {
                console.error(error);
            }
        },
        async showAllAccounts() {
            try {
                const response = await axios.post("/get-accounts", { user_token: JSON.parse(localStorage.getItem("accessToken")) });
                if (response?.data?.status === "Unauthorized") {
                    removeLocalStorageItems();
                    router.push({ name: "auth-login" });
                    return;
                }
                if(response?.data?.accounts){
                    this.accountNames = response.data.accounts.map(account => ({ label: account.account_name, value: account.id }));
                    return;
                }
                removeLocalStorageItems();
                router.push({ name: "auth-login" });
            } catch (error) {
                console.error(error);
            }
        }
    }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
